@charset "UTF-8";

:root {
	--clr-text: #FFFFFF;
	--clr-text-dim: #aaaaaa;
	--clr-background: #000000;
	--clr-background-dim: #1a1a1a;
	--clr-orange: #EDAE49;
	--clr-none: rgba(0, 0, 0, 0.0);
	--radius-small: 3px;
	--radius-medium: 5px;
	--radius-large: 10px;
	--radius-circle: 50px;
}

/* External Fonts */

@font-face {
	font-family: "AnonymousBold";
	src: url("fonts/AnonymousPro-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "AnonymousRegular";
	src: url("fonts/AnonymousPro-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Outfit";
	src: url("fonts/Outfit-VariableFont_wght.ttf") format("truetype");
}

/* Global Settings */

html, body {
	margin: 0;
	padding: 0;
	background-color: var(--clr-background);
	color: var(--clr-text);
	font-family: "AnonymousBold";
}

html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

/* Typography */

h1, h2, h3 {
	font-family: "Outfit";
	margin: 0;
}

.subtext {
	font-size: 0.8rem;
	color: var(--clr-text-dim);
	
}

a {
	color: var(--clr-text);
}

a:visited {
	color: var(--clr-text);
}

a:link {
	text-decoration: none;
}

/* Navigation */

.navbar {
	display: flex;
	position: fixed;
	top: 0;
	width: 100%;
	padding: 20px;
	justify-content: space-between;
	background-color: var(--clr-background);
	z-index: 100;
	transition: max-height 2s;
}

.navbar.navbar-expand {
	background: none;
	border-bottom: none;
}

.navbar .nav-group {
	display: flex;
	align-items: center;
	gap: 20px;
}

.navbar.navbar-collapse.navbar-open {
	flex-direction: column;
	padding: 20px;
	align-items: flex-start;
	justify-content: flex-start;
	height: 100%;
	border: none;
}

.navbar.navbar-collapse:not(.navbar-open) .navbar-collapse-content {
	display: none;
}

.navbar.navbar-collapse .container {
	margin: 0;
	padding: 0;
	justify-content: space-between;
}

.navbar-collapse-content {
	padding: 40px 0;
}

.navbar-collapse-content .nav-group {
	align-items: flex-start;
	flex-direction: column;
}

.navbar-collapse-content .nav-group .nav-item {
	color: var(--clr-text-dim);
	font-size: 40px;
	font-family: "Outfit";
	transition: 0.1s ease-in-out;
}

.navbar-collapse-content .nav-group .nav-item:hover {
	color: var(--clr-text);
}

.navbar-collapse-content .nav-group button {
	padding: 0;
}

/* Container */

.container {
	display: inherit;
	align-items: inherit;
	justify-content: inherit;
	width: 100%;
	padding: 0 20px;
}

.container.container-page {
	padding: 20px;
	padding-top: 80px;
}

.container.container-hero-image {
	display: flex;
	align-items: center;
	justify-content: center;
}

.container.container-album-gallery {
	display: flex;
	flex-direction: column;
	padding: 0;
	gap: 20px;
}

.album-gallery, .container.photo-gallery-grid {
	padding: 0;
	display: grid;
	gap: 20px;
}

.container.photo-gallery-list {
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 160px;
}

.album-gallery {
	grid-template-columns: 1fr 1fr 1fr;
}

.container.photo-gallery-grid {
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.container.container-subnav {
	display: flex;
	justify-content: space-between;
	padding: 0 0 20px 0;
	gap: 20px;
}

.container-subnav button, .container-subnav i {
	color: var(--clr-text);
}

.subnav-left, .subnav-right {
	display: flex;
	gap: 20px;
}

.container.container-modal {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	padding: 20px;
	background-color: var(--clr-background);
	z-index: 100;
}

.container.container-title, .container-title .metadata {
	justify-content: center;
	text-align: center;
	padding: 0 0 20px 0;
}

@media only screen and (max-width: 768px) {
	.album-gallery, .container.photo-gallery-grid {
		grid-template-columns: 1fr 1fr;
	}

	.container.photo-gallery-list {
		gap: 20px;
	}
}

/* Buttons */

.button {
	display: flex;
	gap: 10px;
	cursor: pointer;
	font-family: "AnonymousBold";
	background: none;
	border: none;
	color: var(--clr-text);
	font-size: 1rem;
	transition: 0.2s ease-in-out;
}

.button.button-nav-expand {
	border: none;
}

.button.button-nav-expand img {
	width: 20px;
}

.button.button-filter {
	position: fixed;
	bottom: 20px;
	padding: 15px;
	background-color: var(--clr-background);
	border: 1px solid var(--clr-text);
	border-radius: var(--radius-medium);
}

.container-subnav .button {
	font-family: "AnonymousBold";
	border: none;
	border-bottom: 2px solid var(--clr-none);
	padding: 10px 0;
}

.container-subnav .button.active {
	border-bottom: 2px solid var(--clr-text);
}

/* Input */

input {
	font-size: 1rem;
	font-family: "Outfit";
	color: var(--clr-text);
	background: var(--clr-background);
	border: 1px solid var(--clr-text);
	border-radius: var(--radius-small);
	padding: 5px;
}

.phone-input-group, .code-input-group {
	display: flex;
	align-items: center;
	gap: 10px;
}

.code-input-group {
	gap: 10px;
}

.modal-login input {
	border: 2px solid var(--clr-text);
	border-radius: 0px;
	width: 200px;
}

.phone-input-group .country-code {
	font-family: "Outfit";
	font-size: 1.5rem;
	font-weight: bold;
}

.input-lg {
	font-size: 1.5rem;
}

/* Login */
.modal-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-login {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	width: 350px;
	padding: 40px;
	border: 2px solid var(--clr-text);
}

.modal-login .subtext {
	margin-top: 10px;
}

.modal-login p {
	margin: 0;
}

@media only screen and (max-width: 768px) {
	.modal-login {
		width: 100%;
	}
}

/* Tiles */

.tile {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	border-radius: 3px;
	overflow: hidden;
}

.tile-album .tile-image, 
.photo-gallery-grid .tile-photo .tile-image {
	width: 100%;
	object-fit: cover;
	border: none;
}

.photo-gallery-list .tile-photo {
	display: block;
}

.photo-gallery-list .tile-photo .tile-image {
	display: block;
	max-width: 50%;
	max-height: 600px;
	margin: auto;
}

.tile-album .tile-image {
	height: 30vw;
}

.photo-gallery-grid .tile-photo .tile-image {
	height: 18vw;
}

.tile-album .tile-info {
	display: none;
	position: absolute;
	flex-direction: column;
	color: var(--clr-orange);
	width: 100%;
	background-color: rgba(0,0,0,0.9);
	bottom: 0;
	padding: 20px;
	font-family: "AnonymousBold";
	font-size: 0.8rem;
}

.tile-info p {
	text-transform: uppercase;
	margin: 0;
}

.tile-album:hover .tile-info {
	display: flex;
}

@media only screen and (max-width: 768px) {
	.tile-album .tile-image, .photo-gallery-grid .tile-photo .tile-image {
		height: 40vw;
	}

	.photo-gallery-list .tile-photo .tile-image {
		max-width: 100%;
	}
}

/* Images */

.hero-image {
	height: 500px;
}

/* Lightbox */

.modal-header {
	display: flex;
	width: 100%;
	height: 50px;
	padding: 10px 0;
	justify-content: flex-end;
}

.modal-body {
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
}

.lightbox-image-container {
	flex: 0 0 60%;
	text-align: center;
	max-width: 60%;
	padding: 20px;
}

.lightbox-image {
	max-width: 100%;
	max-height: 80vh;
}

.camera-image {
	max-height: 50px;
}

.lightbox-info {
	max-width: 40%;
	padding: 20px;
	font-family: "AnonymousBold";
	font-size: 0.8rem;
	color: var(--clr-text-dim);
}

.lightbox-table {
	width: 100%;
    border-collapse: collapse;
}

.lightbox-table td {
	height: 30px;
	padding: 10px 0;
    border: none;
}

.lightbox-table td:first-child {
	width: auto;
	font-size: 10px;
	text-transform: uppercase;
}

.lightbox-table td:nth-child(2) {
	font-family: "Outfit";
	font-weight: bold;
	font-size: 14px;
}

.histogram-container {
	position: relative;
	text-align: center;
}

.histogram-container .arrow-left, 
.histogram-container .arrow-right, 
.histogram-container .arrow-up, 
.histogram-container .arrow-down {
	color: var(--clr-text-dim);
	position: absolute;
}

.histogram-container .arrow-left {
	top: 50%;
	left: 20px;
}

.histogram-container .arrow-right {
	top: 50%;
	right: 20px;
}

.histogram-container .arrow-up {
	top: 20px;
	right: 50%;
}

.histogram-container .arrow-down {
	bottom: 20px;
	right: 50%;
}

@media only screen and (max-width: 768px) {
	.modal-body {
		flex-direction: column;
		justify-content: flex-start;
	}

	.lightbox-info {
		max-width: 100%;
		width: 100%;
	}

	.lightbox-image-container {
		flex: auto;
		max-width: 100%;
	}

	.lightbox-image-container, .lightbox-info {
		padding: 20px 0;
	}

	

}

/* Metadata */

.album-year {
	padding: 20px 0;
}

.metadata {
	display: flex;
	margin-top: 10px;
	gap: 30px;
}

.metadata-item {
	font-size: 0.8rem;
	color: var(--clr-orange);
}

.metadata-item svg {
	padding-right: 8px;
}

.metadata-item .metadata-link {
	color: var(--clr-orange);
}

.metadata-item .metadata-link:hover {
	text-decoration: underline;
	cursor: pointer;
}

.color-swatches {
	display: flex;
	gap: 10px;
}

.color-swatch {
	border-radius: var(--radius-small);
	width: 20px;
	height: 20px;
}

@media only screen and (max-width: 768px) {
	.metadata {
		flex-direction: column;
		gap: 10px;
	}
}

/* Map */

.container.container-fullscreen-map {
	display: flex;
	align-items: flex-start;
	padding: 0;
	padding-top: 70px;
	height: 100vh;
}

.container.container-map {
	height: 100%;
	padding: 0;
	width: 67%;
}

.container.container-locations {
	display: flex;
	flex-direction: column;
	width: 33%;
	height: 100%;
	overflow: scroll;
}

.google-map > div {
	background-color: var(--clr-background) !important;
}

.location-tile {
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	width: 100%;
	padding: 15px;
	border: 1px solid var(--clr-none);
	border-radius: 5px;
	transition: 0.3s ease-in-out;
}

.location-tile.active {
	border: 1px solid var(--clr-text);
}

.location-tile:hover {
	border: 1px solid var(--clr-text);
}

.location-tile-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 10px;
	width: 80%;
}

.location-tile-button {
}

.map-marker {
	border-radius: 5px;
	cursor: pointer;
	width: 10px;
	height: 10px;
	background-color: black;
	border: 1px solid var(--clr-orange);
}

.map-marker:hover {
	background-color: var(--clr-orange);
}

.marker-detail {
	font-family: "AnonymousBold";
	font-size: 10px;
	z-index: 100;
	padding: 10px;
	position: absolute;
	text-align: left;
	width: 100px;
	height: auto;
	bottom: 15px;
	right: -47px;
	border: 1px solid white;
	border-radius: var(--radius-small);
	background-color: rgba(0,0,0,0.5);
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
	display:none;
}
.gmnoprint div {
	background:none !important;
}

.location-metadata {
	display: flex;
	gap: 10px;
}

.location-metadata .metadata-item {
	color: var(--clr-text-dim);
	padding: 5px 10px;
	border-radius: 3px;
	background-color: var(--clr-background-dim);
}

.location-metadata .metadata-item:hover {
	background-color: var(--clr-background);
}


@media only screen and (max-width: 768px) {
	.container.container-fullscreen-map {
		flex-direction: column;
	}

	.container.container-map {
		height: 50vh;
		width: 100%;
	}

	.container.container-locations {
		padding: 20px;
		width: 100%;
	}


}

/* Filtering */

.modal-body.modal-body-filters {
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.modal-footer {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 20px;
	width: 100%;
	background: linear-gradient(var(--clr-background), rgba(0, 0, 0, 0), to top);
}

.filter-group {
	width: 100%;
}

.filter-group.inactive {
	opacity: 50%;
}

.filter-group h3 {
	margin-bottom: 20px;
}

.filter-group h5 {
	margin: 10px 0;
}

.filter-group .filter-button {
	display: inline-block;
	font-size: 0.8rem;
	padding: 5px 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	color: var(--clr-text-dim);
	border: 1px solid var(--clr-text-dim);
	border-radius: var(--radius-medium);
}

.filter-group .filter-button-color {
	width: 32px;
	height: 32px;
	border: 3px solid var(--clr-none);
	border-radius: var(--radius-small);
}

.filter-group .filter-button.selected {
	background-color: var(--clr-text);
	border: 1px solid var(--clr-text);
	color: var(--clr-background);
}

.filter-group .filter-button-color.selected {
	border: 3px solid var(--clr-text);
}

.filter-group .button-filter-apply {
	width: 100%;
	padding: 15px;
	justify-content: center;
	border: 1px solid var(--clr-text);
	border-radius: var(--radius-medium);
}